import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Mapbox from "!mapbox-gl";
// import MapboxDrawRectangle from "mapbox-gl-draw-rectangle-mode";
// import DrawRectangle from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import RotateMode from "mapbox-gl-draw-rotate-mode";
import * as locationActions from "../../../actions/locations";
import * as selectedActions from "../../../actions/selected";
import style from "./style.module.scss";
import { getMapBounds } from "../helpers";

let mapbox;
let mapboxDraw;
let shouldUpdateMap;
let combinedFeatures;
let savedFeatureMapping;
let mapHasLoaded;
let usesMazemap = false;

class DrawMap extends Component {
  
  constructor(props) {
    // console.log("DrawMap.constructor", props);
    super(props);
    shouldUpdateMap = false;
    mapHasLoaded = false;
    combinedFeatures = {};
    savedFeatureMapping = {};

    this.state = {
      drawMode: get(props, "drawMode", MapboxDraw.modes.SIMPLE_SELECT),
      showMap: false
    };
    
    this.changeDrawMode = this.changeDrawMode.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
    this.onMapRender = this.onMapRender.bind(this);
    this.onFeaturesSelected = this.onFeaturesSelected.bind(this);
    this.onRotateEnd = this.onRotateEnd.bind(this);
    this.addLocationGeoJson = this.addLocationGeoJson.bind(this);
    this.loadConfig = this.loadConfig.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  loadConfig() {

    // Build config
    const config = {
      container: "mazemap-container",
      autoSetRTLTextPlugin: false
    };

    if (get(this.props.location, "geoJsonFeature.geometry", null)) {
      // Center for mapped location
      const llb = getMapBounds([this.props.location.geoJsonFeature]);
      config.bounds = llb.toArray();
      config.fitBoundsOptions = { padding: 50 };
    }
    else if (get(this.props.map, "features[0].geometry", null)) {
      // Center for unmapped location
      const llb = getMapBounds(this.props.map.features);
      config.bounds = llb.toArray();
      config.fitBoundsOptions = { padding: 50 };
    }
    else {
      // Skandinavia
      config.center = { lng: 17.7099499, lat: 64.55875 };
      config.zoom = 3.5;
    }

    if (this.props.location.floorLevel !== "" && !isEmpty(this.props.company.mazemapCampusTag)) {
      usesMazemap = true;
      config.campuses = this.props.company.mazemapCampusTag;
      config.zLevel = this.props.location.floorLevel;
      config.zLevelControl = false;
      
      let key;
      if (!isEmpty(this.props.company.mazemapApiKey)) {
        if (this.props.company.mazemapApiKey.length === 32) {
          key = `Bearer ${this.props.company.mazemapApiKey}`;
        }
        else {
          key = this.props.company.mazemapApiKey;
        }

        Mazemap.Config.setMazemapAuthToken(key); // eslint-disable-line no-undef
     }

      mapbox = new Mazemap.Map(config); // eslint-disable-line no-undef
    }
    else {
      usesMazemap = false;

      Mapbox.accessToken = "pk.eyJ1IjoidG5zbWFydHgiLCJhIjoiY2s4aXJ6MTYxMDQycjNsbjYxZTA2ZmY5diJ9.6cYHg6iYWpNrtSuAjf-Eyg";
      config.style = "mapbox://styles/mapbox/streets-v11";
      config.attributionControl = false;

      mapbox = new Mapbox.Map(config).addControl(new Mapbox.AttributionControl({
        compact: true
      }));
    }

    // mapbox.addControl(new Mazemap.mapboxgl.NavigationControl());  // eslint-disable-line no-undef

    console.log("this.state.drawMode", this.state.drawMode);

    const mazeMapOptions = {
      displayControlsDefault: false,
      defaultMode: this.state.drawMode,
      modes: {...MapboxDraw.modes, RotateMode }, //, draw_assisted_rectangle: DrawRectangle // ,draw_rectangle: MapboxDrawRectangle
      controls: {
        polygon: true,
        trash: true
      },
      userProperties: true
    };

    mapboxDraw = new MapboxDraw(mazeMapOptions); 
    mapbox.addControl(mapboxDraw, "top-left");
    
    mapbox.on("draw.create", event => {
      console.log("draw.create", event);
      const feature = get(event, "features[0]", null);
      if (feature) {
        this.props.addCreatedFeature(feature);
      }
    });

    mapbox.on("draw.update", event => {
      console.log("draw.update", event);
      const featureCollection = mapboxDraw.getAll();
      console.log("draw.update.featureCollection", featureCollection);
      this.props.storeEditedFeatures(featureCollection.features);
    });

    mapbox.on("draw.combine", event => {
      console.log("draw.combine", event);
      combinedFeatures[event.createdFeatures[0].id] = event.deletedFeatures;
    });

    mapbox.on("draw.uncombine", event => {
      console.log("draw.uncombine", event);
      console.log("combinedFeatures", combinedFeatures);
      event.createdFeatures.forEach((createdFeature,index) => {
        const savedFeatures = combinedFeatures[event.deletedFeatures[0].id];
        savedFeatureMapping[createdFeature.id] = savedFeatures[index];
      });

      console.log("draw.uncombine.done => featureCollection", mapboxDraw.getAll());
      console.log("savedFeatureMapping", savedFeatureMapping);
      this.remapFeatures(savedFeatureMapping, mapboxDraw.getAll());
    });

    console.log("MapboxDraw.modes", MapboxDraw.modes);

    mapbox.on("load", this.onMapLoad);

    mapbox.on("draw.render", this.onMapRender);

    mapbox.on("draw.selectionchange", event => {
      console.log("draw.selectionchange", event);
      const mode = mapboxDraw.getMode();
      console.log("mapboxDraw.getMode()", mode);

      this.onFeaturesSelected(event.features, mode);
    });

    RotateMode.rotatestart = function(selectedFeature,originalCenter) {
      console.log('ROTATESTART');
      console.log('feature: ',selectedFeature);
      console.log('center: ',originalCenter);
   }
   
   RotateMode.rotating = function(selectedFeature,originalCenter,lastMouseDown) {
      // console.log('ROTATING');
      // console.log('feature: ',selectedFeature);
      // console.log('center: ',originalCenter);
      // console.log('lastMouseDown: ',lastMouseDown);
   }
   
   RotateMode.rotateend = this.onRotateEnd;
  }

  componentDidMount() {
    this.loadConfig();
  }

  componentWillUnmount() {
    mapbox.off("load", this.onMapLoad);
    mapbox.off("draw.render", this.onMapRender);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.location.floorMap === undefined && nextProps.location.floorMap !== undefined) {
      shouldUpdateMap = true;
    }

    if (mapHasLoaded) {
      if (nextProps.selectedMapFeatures) {
        if (nextProps.mapMode === "direct_select") {
          const featureId = get(nextProps.selectedMapFeatures, "[0].id", null);
          if (featureId) {
            mapboxDraw.changeMode(nextProps.mapMode, { featureId });
          }
        }
        else {
          mapboxDraw.changeMode(nextProps.mapMode, { featureIds: nextProps.selectedMapFeatures.map((feature) => (feature.id)) });
        }
      }
      else {
        mapboxDraw.changeMode(nextProps.mapMode);
      }
    }
  }
  
  componentDidUpdate() {
    if (shouldUpdateMap) {
      shouldUpdateMap = false;
      this.onMapLoad();
    }
  }

  onMapLoad() {
    const mode = mapboxDraw.getMode();
    mapHasLoaded = true;

    // Only hide map initially if drawable
    // this.setState({ showMap: false });

     // Add geometry
     if (get(this.props.map, "features[0]", false)) {
      this.addLocationGeoJson();
    }

    // if (!isEmpty(this.props.location.floorMap)) {

    //   // Zoom
    //   // const coordinates = get(this.props.location, "geoJsonFeature.geometry.coordinates[0]", null);
    //   // if (coordinates) {
    //   //   const bounds = getBounds(coordinates);
    //   //   const boundsWithMargin = getBoundsWithMargin(bounds, 0.0001);
    //   //   const boundsWithMarginArray = boundsWithMargin.toArray();
    //   //   mapbox.fitBounds(boundsWithMarginArray, { duration: 0 });
    //   // }

    //   // Add geometry
    //   if (!isEmpty(this.props.location.floorMap.features)) {
    //     this.addLocationGeoJson();
    //   }
    //   // if (!isEmpty(this.props.sensors)) {
    //   //   this.addSensorGeoJson();
    //   // }
    // }

    // Set map mode
    if (this.props.mapMode) {
      // Select in map if selected elsewhere
      // console.log("asfdeocef32");
      if (this.props.selectedMapFeatures) {
        // console.log("sadfoihasdf", this.props.selectedMapFeatures);
        
        if (this.props.mapMode === "direct_select") {
          const featureId = get(this.props.selectedMapFeatures, "[0].id", null);
          if (featureId) {
            mapboxDraw.changeMode(this.props.mapMode, { featureId });
          }
        }
        else {
          mapboxDraw.changeMode(this.props.mapMode, { featureIds: this.props.selectedMapFeatures.map((feature) => (feature.id)) });
        }
      }
      else {
        mapboxDraw.changeMode(this.props.mapMode);
      }
    }
  }

  onMapRender() {
    // console.log("draw.render", event);
    if (mapHasLoaded && !this.state.showMap) {
      this.setState({ showMap: true });
    }
  }

  onFeaturesSelected(features, mode) {

    if (this.props.selectedMapFeatures.length !== features.length) {
      this.props.setSelectedFeatures(features, mode);
    }
    else {
      // Check for changes
      let isDifferent = false;
      this.props.selectedMapFeatures.forEach(selectedMapFeature => {
        features.forEach(feature => {
          if (selectedMapFeature.id === feature.id) {
            isDifferent = true;
          }
        })
      });

      if (isDifferent) {
        this.props.setSelectedFeatures(features, mode);
      }
    }

    
    
    // if (feature) {
    //   this.props.selectMapFeature(feature);
    // }
    // else {
    //   this.props.deselectMapFeature();
    // }
    // else {
    //   this.props.deselectMapFeature(feature);
    // }
  }

  onRotateEnd(selectedFeature) {
    const featureCollection = mapboxDraw.getAll();
    this.props.storeEditedFeatures(featureCollection.features);
  }

  changeDrawMode() {
    // console.log("DrawMap.changeDrawMode()");
    if (this.state.drawMode === "RotateMode") {
      // console.log("currentDrawMode === RotateMode");
      mapboxDraw.changeMode(MapboxDraw.modes.SIMPLE_SELECT);
      this.setState({ drawMode: MapboxDraw.modes.SIMPLE_SELECT });
    }
    else {
      // console.log("currentDrawMode === simple_select && !hasControlUI", mapboxDraw);
      // mapboxDraw = new MapboxDraw({ modes: Object.assign(MapboxDraw.modes, { RotateMode: RotateMode }) });
      mapboxDraw.changeMode("RotateMode");
      this.setState({ drawMode: "RotateMode" });
    }
  }

  addLocationGeoJson() {
    this.props.map.features.forEach(feature => {
      if (feature.id) {
          mapboxDraw.add(feature);
      }
    });

    if (mapbox.getLayer("mm-campus-label") !== undefined) {
      mapbox.setLayoutProperty("mm-campus-label", "visibility", "none");
    }

    if (mapbox.getLayer("mm-building-label") !== undefined) {
      mapbox.setLayoutProperty("mm-building-label", "visibility", "none");
    }

    if (mapbox.getLayer("mm-poi-label") !== undefined) {
      mapbox.setLayoutProperty("mm-poi-label", "visibility", "none");
    }
  }

  // addSensorGeoJson() {
  //   this.props.sensors.forEach(sensor => {
  //     console.log("addSensorGeoJson", sensor);
  //     const feature = get(sensor, "properties.metadata.geoJsonFeature", {});
  //     console.log("addSensorGeoJson", feature);
  //     if (feature.id) {
  //       mapboxDraw.add(feature);
  //     }
  //   });
  // }

  remapFeatures(featureMap, featureCollection) {

    if (mapHasLoaded && this.state.showMap) {

      /* eslint-disable no-param-reassign */
      featureCollection.features.forEach(feature => {
        if (featureMap[feature.id]) {
          feature.properties = featureMap[feature.id].properties;
          feature.id = featureMap[feature.id].id;
        }
      });
      /* eslint-enable no-param-reassign */

      console.log("remapFeatures", featureCollection);
  
      // Redraw
      mapboxDraw.deleteAll();
      featureCollection.features.forEach(feature => {
        if (feature.id && !feature.properties.companyMap) {
          mapboxDraw.add(feature);
        }
      });

      this.props.storeEditedFeatures(featureCollection.features);
    }
  }

  saveChanges() {
    const featureCollection = mapboxDraw.getAll();
    console.log(featureCollection);

    featureCollection.features.forEach(feature => {
      console.log(feature);
      const editedLocation = {};
      editedLocation.geoJsonFeature = feature;
      this.props.saveGeoJsonFeature(feature.properties.locationId, editedLocation);
    });
  }

  render() {
    // console.log("DrawMap.render", this.props);
    return (
      <div className={style.mapContainer}>
        <div id="mazemap-container" className={style.map} style={{ opacity: this.state.showMap ? 1 : 0 }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mapFeature: state.selected.mapFeature,
    selectedMapFeatures: state.selected.features,
    mapMode: state.selected.mapMode
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveGeoJsonFeature: locationActions.saveGeoJsonFeature,
    selectMapFeature: selectedActions.selectMapFeature,
    deselectMapFeature: selectedActions.deselectMapFeature,
    setSelectedFeatures: selectedActions.setSelectedFeatures,
    storeEditedFeatures: selectedActions.storeEditedFeatures,
    setMapMode: selectedActions.setMapMode,
    addCreatedFeature: selectedActions.addCreatedFeature
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawMap);
